<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        {{ $t("addproduct") }}
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light btn-success btn-hover-scale me-2"
          style="font-size: 14px; font-weight: bold"
          @click="gotoImportExcel"
          @mouseenter="hoverABtn"
        >
          {{ $t("importdatafromexcel") }}
        </button>
        <div v-if="hoverA">
          <DialogExportExcel
            :dialogImportExcel="dialogImportExcel"
            @closeDialogImportExcel="closeDialogImportExcel"
          />
        </div>
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >{{ $t("add") }}
        </button>
      </div>
    </div>
    <div class="card-title row ms-5 me-5 mt-6">
      <div class="col-sm-3 mb-4">
        <input
          v-model="searchInput"
          :placeholder="$t(`search`)" 
          type="text"
          class="form-control"
        />
      </div>
      <!-- <select v-model="searchBy" class="form-select">
        <option value="1">ตามรหัสสินค้า</option>
        <option value="2">ตาม Barcode</option>
        <option value="3">ตาม POG</option>
      </select> -->
      <div class="col-sm-3 mb-3">
        <select
          class="form-select mb-4"
          v-model="groupId"
          id="validationTooltip01"
          required
          @change="getAllType(groupId)"
        >
          <option v-for="item in groups" :key="item" :value="item.id">
            {{ item.code }} |
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="col-sm-3 mb-3">
        <select
          class="form-select mb-4"
          v-model="typeId"
          id="validationTooltip01"
          required
        >
          <option v-for="item in types" :key="item" :value="item.id">
            {{ item.sub_product_group_code }} |
            {{ item.sub_product_group_name }}
          </option>
        </select>
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
           {{ $t("search") }}
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="
                fw-bold
                fs-5
                text-gray-800
                border-bottom-2 border-gray-200
                mw-200px
              "
            >
              <th>{{ $t("no") }}</th>
              <th>{{ $t("productcode") }}</th>
              <th>{{ $t("productname") }}</th>
              <th>{{ $t("cost") }}</th>
              <th>{{ $t("status") }}</th>
              <!-- <th>Barcode</th> -->
              <!-- <th style="width: 20%">ชื่อกลุ่มสินค้าหลัก</th> -->
              <!-- <th style="width: 20%">ชื่อกลุ่มสินค้ารอง</th> -->
              <!-- <th>POG</th>
              <th>ทุน</th>
              <th>ขายส่ง</th>
              <th>ขายปลีก</th>
              <th>ขาย Pro</th>
              <th>QTY</th>
              <th>Intransit</th>
              <th>Min</th>
              <th>Max</th> -->
              <!-- <th>Status</th> -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="15">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ !showing1 ? index + 1 : showing1 + index }}</td>
              <td>{{ item.code }}</td>
              <td>{{ item.name }}</td>
              <td v-if="item.productPrices.length != 0">
                {{ item.productPrices[0].cost }}
              </td>
              <td v-else>-</td>
              <td v-if="item.status == 'ขายปกติ'" style="color: green">
                {{ item.status }}
              </td>
              <td v-else style="color: red">{{ item.status }}</td>
              <!-- <td>{{ item.product_barcode }}</td>
              <td>{{ item.product_pog }}</td>
              <td>{{ item.product_cost_price }}</td>
              <td>{{ item.product_wholesale_price }}</td>
              <td>{{ item.product_retail_price }}</td>
              <td>{{ item.product_promotion_price }}</td>
              <td>{{ item.product_bring_qty }}</td>
              <td>{{ item.product_in_transit }}</td>
              <td>{{ item.product_min_stock }}</td>
              <td>{{ item.product_max_stock }}</td> -->
              <!-- <td
                @click="item.status !== '1' ? handleApprove(item) : ''"
                @mouseenter="hoverBBtn"
                :style="
                  item.status === '1'
                    ? 'cursor: ; color: green'
                    : item.status === '0'
                    ? 'cursor: pointer; color: blue'
                    : 'cursor: pointer; color: red'
                "
              > -->
              <!-- <td
                :style="
                  item.status === '1'
                    ? 'cursor: ; color: green'
                    : item.status === '0'
                    ? 'cursor: ; color: blue'
                    : 'cursor: ; color: red'
                "
              > -->
              <!-- {{
                  item.status === "1"
                    ? "อนุมัติ"
                    : item.status === "0"
                    ? "รออนุมัติการเพิ่ม"
                    : "ไม่อนุมัติ"
                }}
              </td> -->
              <td>
                <div class="row">
                  <div class="col-sm-6 d-flex justify-content-md-end">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-success
                        p-0
                      "
                      @click="goToEdit(item.id)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </div>
                  <div class="col-sm-6">
                    <button
                      @mouseenter="hoverBBtn"
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-danger
                        p-0
                      "
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="hoverB">
      <DialogConfirmByPass
        :dialogConfirmByPass="dialogConfirmByPass"
        :loadingUpdate="loadingUpdate"
        @submitConfirmPass="submitConfirmPass"
        @closeDialogConformByPass="closeDialogConformByPass"
      />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

import DialogConfirmByPass from "./components/dialogConfirmByPass.vue";
import DialogExportExcel from "./components/dialogImportExcel.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";

export default {
  components: {
    DialogExportExcel,
    DialogConfirmByPass,
    Pagination,
    Search,
    Loader,
  },
  setup() {
    document.title = "bem-document-demo Product Library";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dialogImportExcel: false,

    dialogConfirmByPass: false,

    showing1: null,

    hoverA: false,
    hoverB: false,

    dataItemsShow: [],
    tableItems: [],
    itemApprove: {},

    searchInput: "",
    searchBy: "1",
    allData: [],
    groups: [],
    types: [],
    groupId: 0,
    typeId: 0,
  }),

  created() {
    this.getAll();
    this.getAllGroup();
  },

  methods: {
    async getAllGroup(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.mainProduct.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.groups = responseData.data;
        this.loading = false;
      }
    },
    async getAllType(groupId) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.subProduct.getAllByProductGroupId(groupId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.types = responseData.data;

        this.loading = false;
      }
    },
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.product.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER.",
        });
      }
    },

    hoverABtn() {
      this.hoverA = true;
      this.hoverB = false;
    },
    hoverBBtn() {
      this.hoverB = true;
      this.hoverA = false;
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    goToNew() {
      this.$router.push({
        path: "/warehouse/WH4/new",
        query: {},
      });
    },

    goToEdit(id) {
      let routeData = this.$router.resolve({
        name: "WH4-edit",
        query: {
          id: id,
        },
      });
      window.open(routeData.href, "_blank");
      // this.$router.push({
      //   name: "WH4-edit",
      //   query: {
      //     id: id,
      //   },
      // });
    },
    gotoImportExcel() {
      // this.$router.push("/warehouse/WH4/importexcel");
      // document.getElementById("kt_modal_1").click();
      this.dialogImportExcel = true;
    },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `ต้องการลบ ${item.name} หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          // this.confirmDelete(item, index);
          this.handleApprove(item);
        }
      });
    },
    async confirmDelete() {
      let deleteResponse = [];
      const findIndex = this.dataItemsShow.indexOf(this.itemApprove);

      deleteResponse = await whApi.product.delete(this.itemApprove.id);

      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(findIndex, 1);
          this.tableItems.splice(findIndex, 1);
          this.getAll(1);
        });
      }
    },
    async statusApprove(isApprove) {
      if (isApprove) {
        this.confirmDelete();
      }
      // let updateResponse = [];
      // const id = this.itemApprove.id;

      // this.itemApprove = {
      //   ...this.itemApprove,
      //   status: isApprove ? 1 : 2,
      // };
      // updateResponse = await whApi.product.update(id, this.itemApprove);
      // if (updateResponse.response_status === "SUCCESS") {
      //   if (isApprove) {
      //     Swal.fire({
      //       icon: "success",
      //       title: `อนุมัติสำเร็จ`,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //   } else {
      //     Swal.fire({
      //       icon: "error",
      //       title: `ไม่อนุมัติ`,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //   }
      //   this.loadingUpdate = false;
      //   this.getAll(1);
      // } else {
      //   Swal.fire({
      //     icon: "warning",
      //     title: "อนุมัติไม่สำเร็จ !",
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      //   this.loadingUpdate = false;
      // }
    },
    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    closeDialogImportExcel() {
      this.dialogImportExcel = false;
    },
    textSearch(val) {
      console.log({ val });
    },
    async search() {
      this.loading = true;
      let responseSearch = [];

      if (this.searchBy === "1") {
        responseSearch = await whApi.product.search(
          {
            searchByCode: this.searchInput,
          },
          "0"
        );
      }

      if (this.searchBy === "2") {
        responseSearch = await whApi.product.search(
          {
            searchByBarcode: this.searchInput,
          },
          "0"
        );
      }
      if (this.searchBy === "3") {
        responseSearch = await whApi.product.search(
          {
            searchByPog: this.searchInput,
          },
          "0"
        );
      }

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
